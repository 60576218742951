<template>
  <section>
    <v-card class="d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4" flat >
      <span class="f15">{{$t('Amount')}}</span>
      <span class="primary--text f14">{{ item.totalCash | VMask(currencyMask) }} {{ $t('RIAL') }}</span>
    </v-card>
    <v-card class="d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4" flat >
      <span class="f15">{{$t('Issue tracking')}}</span>
      <span class="primary--text f14">{{item.orderId}}</span>
    </v-card>
    <v-card class="d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4" flat >
      <span class="f15">{{$t('Deposit date')}}</span>
      <span class="f14 primary--text d-flex flex-row align-items-center">
        <i class="far fa-calendar-alt ms-0 me-1"/>
        {{ item.createDate | moment("dddd , jD jMMMM jYYYY") }} 
        <i class="far fa-clock ms-2 me-1"/>
        {{ item.createDate | moment("HH:MM") }} 
      </span>
    </v-card>
    <v-card class="d-flex justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4" flat >
      <span class="f15">{{$t('IBAN')}}</span>
      <span class="primary--text f14">{{item.iban}}</span>
    </v-card>
    <v-card class="d-flex flex-column justify-content-between light-blue lighten-5 rounded-lg py-2 px-4 mb-4" flat >
      <span class="f15">{{$t('Description')}}</span>
      <p class="primary--text f15 px-2 mb-0" v-if="item.description" v-html="item.description"></p>
      <p class="primary--text f14 px-2 mb-0" v-else >{{$t('No description')}}</p>
    </v-card>

    <span class="f16 fw-bold">{{$t('Transactions')}}</span>

    <div v-if="transactions && transactions.length > 0">
      <v-card class="my-2" v-for="(item, index) in transactions" :key="index" flat hover ripple color="white">
        <b-list-group-item class="d-flex align-items-center rounded-xl">
          <div class="d-flex flex-column ms-4 me-0 align-items-start justify-content-center">
            <div class="d-flex flex-row">
              <span class="f14" v-if="item.reason == 'CHARGE_WALLET'">{{ $t('Wallet charge') }}</span>

              <span class="f14" v-if="item.reason == 'EXAM'">{{ item.clientFullName }}</span>
              <span class="f12 ms-2 me-0 primary lighten-5 rounded-pill px-3 py-1 primary--text" v-if="item.reason == 'EXAM'">{{ $t('Exam') }}</span>

              <span class="f14" v-if="item.reason == 'CONSULTANT'">{{ item.clientFullName }}</span>
              <span class="f12 ms-2 me-0 primary lighten-5 rounded-pill px-3 py-1 primary--text" v-if="item.reason == 'CONSULTANT'">{{ $t('Consultant') }}</span>

            </div>
            
            <span class="f12 grey--text d-flex flex-row align-items-center">
            <i class="far fa-calendar-alt ms-0 me-1"/>
            {{ item.createDate | moment("dddd , jD jMMMM jYYYY") }} 
            <i class="far fa-clock ms-2 me-1"/>
            {{ item.createDate | moment("HH:MM") }} 
            </span>
          </div>


          <div class="d-flex ms-auto me-0 align-items-end justify-content-center flex-column">
            <span class="f14 red--text" v-if="item.status == 'WITHDRAW'">{{ item.amount | VMask(currencyMask) }} {{ $t('RIAL') }}</span>

            <span class="f14 green--text" v-if="item.status == 'DEPOSIT'">{{ item.amount | VMask(currencyMask) }} {{ $t('RIAL') }}</span>
            <span class="f10 green--text green lighten-5 rounded-pill px-2" v-if="item.status == 'DEPOSIT' && item.isSettled == true">{{ $t('Settled') }}</span>
            <span class="f10 green--text green lighten-5 rounded-pill px-2" v-if="item.status == 'DEPOSIT' && item.isSettled == false && item.settlementId">{{ $t('Awaiting settlement') }}</span>
          </div>
        </b-list-group-item>
      </v-card>
    </div>
    
    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>

  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

export default {
  data() {
    return {
      currencyMask,

      item: {},
      transactions: [],

    }
  },
  methods: {
    getSettlementsDetails(id){
      apiService.getSettlementsDetails(id)
      .then((response) => {
          this.transactions = response.data;
        })
        .catch(() => {
          this.$router.go(-1);
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    
  },
  mounted() {
    this.getSettlementsDetails(this.$route.params.settlementId);

    if (this.$route.params.obj) {
      this.item = this.$route.params.obj;
    }
    else {
      this.$router.go(-1);
    }

  },
}
</script>
